import React from "react"
import { graphql } from "gatsby"

import EventList from "../components/eventList"

export default ({ data }) => (
	<EventList
		type="Talks"
		meta={{
			title: "Talks",
			description:
				"Check out the awesome Java, JavaScript, and Ops talks at Accento on September 28th 2021!",
			keywords: `Java JavaScript DevOps talks Karlsruhe`,
			path: "/talks",
		}}
		lists={[
			{
				snippetId: "talks-intro",
				events: data.talks.nodes,
				showByline: true,
			}
		]}
	/>
)

export const query = graphql`
	query {
		talks: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { fields: { collection: { eq: "talks" } } }
		) {
			...AllEventNodes
		}
	}
`
